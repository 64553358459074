define("discourse/plugins/thread-banner/discourse/components/thread-banner-ui", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: "div",
    // subclasses need this
    layoutName: "components/thread-banner-ui",
    attributeBindings: ["bannerImage", "bannerTitle", "bannerAdvert", "bannerCta", "bannerCtaText"]
  });
});