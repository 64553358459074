define("discourse/plugins/thread-banner/discourse/connectors/topic-above-suggested/thread-banner", ["exports", "discourse/lib/plugin-api", "discourse/plugins/thread-banner/lib/render-thread-banner"], function (_exports, _pluginApi, _renderThreadBanner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    async setupComponent(args, component) {
      (0, _pluginApi.withPluginApi)("*", async api => {
        if (!api.getCurrentUser()) {
          await (0, _renderThreadBanner.renderThreadBanner)(this, args.model);
        }
      });
    }
  };
});