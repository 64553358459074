define("discourse/plugins/thread-banner/lib/render-thread-banner", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.renderThreadBanner = void 0;
  const renderThreadBanner = async (component, model) => {
    component.set('showBanner', false);
    const category = model.get('category');
    const categoryName = category?.name;
    const bannersListByCategory = await (0, _ajax.ajax)("/thread-banner/config.json", {
      type: 'get'
    });
    const bannerData = bannersListByCategory.find(banner => {
      banner.categories = !banner.categories ? "" : banner.categories;
      const categoriesList = banner.categories.split(",").map(item => item.trim());
      return categoriesList.some(category => category === categoryName);
    });
    if (!bannerData) {
      return;
    }
    component.set('bannerImage', bannerData.bannerImage);
    component.set('bannerTitle', bannerData.bannerTitle);
    component.set('bannerAdvert', bannerData.bannerAdvert);
    component.set('bannerCtaText', bannerData.bannerCtaText);
    component.set('bannerCta', bannerData.bannerCta);
    component.set('linkTarget', bannerData.openLinkInNewTab ? '_blank' : '_self');
    component.set('showBanner', true);
  };
  _exports.renderThreadBanner = renderThreadBanner;
});